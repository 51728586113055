// herovideo
document.addEventListener("DOMContentLoaded", function () {
  $(function () {
    const video = $("#heroVideo")[0];
    const playButton = $("#heroVideoPlay");
    const closeButton = $("#closeVideo");
    const heroContent = $(".heroContent");
    const heroVideoStyle = $(".hero.videoStyle");

    function toggleVideoPlay() {
      if (video.paused) {
        video.play();
        playButton.fadeOut();
        heroContent.fadeOut();
        heroVideoStyle.addClass("play");
        setTimeout(function () {
          closeButton.addClass("show");
        }, 200);
      } else {
        video.pause();
        closeButton.removeClass("show");
        setTimeout(function () {
          heroContent.fadeIn();
          playButton.fadeIn();
          heroVideoStyle.removeClass("play");
        }, 200);
      }
    }

    playButton.on("click", function () {
      toggleVideoPlay();
    });

    closeButton.on("click", function () {
      closeButton.removeClass("show");

      video.pause();
      video.currentTime = 0; // Resetujemy czas wideo na początek

      setTimeout(function () {
        heroContent.fadeIn();
        playButton.fadeIn();
        heroVideoStyle.removeClass("play");

        // Wymuszenie ponownego załadowania wideo i ustawienia posteru
        video.load();
      }, 200);
    });

    $("#heroVideo").on("click", function () {
      toggleVideoPlay();
    });

    $("#heroVideo").on("webkitendfullscreen", function (e) {
      closeButton.trigger("click");
    });

    $(document).on("keydown", function (e) {
      if (e.code === "Space") {
        e.preventDefault();
        toggleVideoPlay();
      }
    });

    // Wymuszenie odświeżenia posteru przy inicjalizacji strony
    video.currentTime = 0;
    video.pause();
    video.load();
  });
});


